// /redux/reducers/index.js`

const initialState = {}
//     currentProject: null,
//     errorMessage: null,
//     projectList: [],
//     isLoading: false,
//     account: null,
//     isLoggedIn: false,
//     showNav: !window.matchMedia("(max-width: 768px)").matches,
//     isMobile: window.matchMedia("(max-width: 768px)").matches,
//     chatSlidingWindow: false,
//     useAgent: null,
//     chatLayout: localStorage.getItem('layout') || "column",
// };


export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CURRENT_PROJECT':
            return {...state, currentProject: action.currentProject};
        case 'SET_PROJECT_LIST':
            return {...state, projectList: action.projectList};
        // case 'SET_PROJECT_COUNT':
        //     return {...state, projectCount: action.projectCount};
        case 'SET_LOADING':
            return {...state, isLoading: action.loading};
        case 'SET_ACCOUNT':
            return {...state, account: action.account};
        case 'UPDATE_LOGGED_IN':
            return {...state, isLoggedIn: action.isLoggedIn};
        case 'SET_ERROR_MESSAGE':
            return {...state, errorMessage: action.errorMessage};
        case 'SET_INFO_MESSAGE':
            return {...state, infoMessage: action.infoMessage};
        case 'SET_CURRENT_CHAT_ID':
            return {...state, chatId: action.chatId};
        case 'SET_SHOW_NAV':
            return {...state, showNav: action.showNav};
        case 'SET_IS_MOBILE':
            return {...state, isMobile: action.isMobile};
        case 'SET_CURRENT_USAGE':
            return {...state, currentUsage: action.currentUsage};
        case 'SET_RETRY_LOGIN':
            return {...state, retryLogin: action.retryLogin};
        case 'SET_FORCE_LOGIN':
            return {...state, isForceLogin: action.isForceLogin};
        case 'SET_SLIDING_WINDOW':
            return {...state, chatSlidingWindow: action.chatSlidingWindow};
        // case 'SET_USE_PREMIUM_MODEL':
        //     return {...state, usePremiumModel: action.usePremiumModel};
        case 'SET_DATA_STORE':
            return {...state, useDataStore: action.useDataStore};
        case 'SET_MODEL':
            return {...state, useModel: action.useModel};
        case 'SET_TEMPERATURE':
            return {...state, temperature: action.temperature};
        case 'SET_AGENT':
            return {...state, useAgent: action.useAgent};
        case 'SET_USE_MAXIMIZE':
            return {...state, useMaximize: action.useMaximize};
        case 'SET_BALANCE':
            return {...state, balance: action.balance};
        case 'SET_CHAT_LAYOUT':
            return {...state, chatLayout: action.chatLayout};
        case 'SET_MODEL_LIST':
            return {...state, modelList: action.modelList};
        case 'SET_TENANT_NAME':
            return {...state, tenantName: action.tenantName};
        case 'SET_PROFILE':
            return {...state, profile: action.profile};
        case 'SET_DISABLE_FORMAT':
            return {...state, isDisableFormat: action.isDisableFormat};
        case 'SET_SHOW_SYS_MESSAGE':
            return {...state, showSysMessage: action.showSysMessage};
        case 'SET_SHOW_ON_TOP':
            return {...state, showOnTop: action.showOnTop};
        // case 'SET_LOGGED_USING':
        //     return {...state, loggedUsing: action.loggedUsing};
        // case 'SET_TOKEN':
        //     return {...state, token: action.token};
        case 'SET_WAITING':
            return {...state, waiting: action.waiting};
        // case 'SET_RANKING':
        //     return {...state, ranking: action.ranking};
        default:
            throw new Error("Undefined action: "+ action.type);
    }
}


